<template>
  <router-view :key="partner.value" />
</template>

<script lang="ts" setup>
import { useAlertStore } from "@presentation/store/alert-store";
import { useAccountStore } from "@presentation/store/account-store";
import { message as antMessage } from "ant-design-vue";
import { storeToRefs } from "pinia";
import { watch } from "vue";
const accountStore = useAccountStore();
const alertStore = useAlertStore();
const { message, type } = storeToRefs(alertStore);

const { partner } = storeToRefs(accountStore);
watch(
  [message, type],
  () => {
    if (message.value === "") return;

    if (type.value === "success")
      return antMessage.success(message.value, undefined, () => {
        alertStore.clear();
      });

    if (type.value === "error")
      return antMessage.error(message.value, undefined, () => {
        alertStore.clear();
      });

    if (type.value === "warning")
      return antMessage.warning(message.value, undefined, () => {
        alertStore.clear();
      });
  },
  { deep: true, immediate: true }
);
</script>
