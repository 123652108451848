import repository from "@config/repository";
import AccountRepository from "@domain/repositories/account-repository";

export default async function loginUseCase (values: {
  account_name: string;
  password: string;
}) {
  const accountRepository =
    repository.get<AccountRepository>(AccountRepository);

  return await accountRepository.login({
    account_name: values.account_name,
    password: values.password,
  });
}
