import { DEFAULT_LIMIT } from "@common/constant";
import {
  IGetRuleDetail,
  IGetRuleList,
  IQueryRule,
  IRuleSubjectList
} from "@domain/interfaces/rule-interface";
import RuleRepository from "@domain/repositories/rule-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class RuleApiRepository implements RuleRepository {
  private api = new ApiService();

  async getList (query: IQueryRule): Promise<IGetRuleList> {
    const result = await this.api.get("/rules", query);

    return {
      isSuccess: result?.status === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }

  async create (data: any): Promise<IGetRuleDetail> {
    const result = await this.api.post("/rules", data);

    return {
      isSuccess: result?.status === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async update (id: string, data: any): Promise<IGetRuleDetail> {
    const result = await this.api.put(`/rules/${id}`, data);

    return {
      isSuccess: result?.status === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async remove (id: string): Promise<IGetRuleDetail> {
    const result = await this.api.remove(`/rules/${id}`);

    return {
      isSuccess: result?.status === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async getSubjectList (): Promise<IRuleSubjectList> {
    const result = await this.api.get("/rules/subject", {});

    return {
      isSuccess: result?.status === 1,
      message: result.message || "",
      data: result.data,
    };
  }
}
