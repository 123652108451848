import { routesName } from "@common/route";
import { useAccountStore } from "@presentation/store/account-store";
import { paramCase, pascalCase } from "change-case-all";
import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: routesName.login,
    component: () => import("@interfaces/views/LoginView.vue"),
    beforeEnter: (to, from, next) => {
      const userStore = useAccountStore();

      if (!userStore.isLogin) return next();

      return next(routesName.admin.home);
    },
  },
  {
    path: "/sign-up",
    name: routesName.signUp,
    component: () => import("@interfaces/views/SignUpView.vue"),
    beforeEnter: (to, from, next) => {
      const userStore = useAccountStore();

      if (!userStore.isLogin) return next();

      return next(routesName.admin.home);
    },
  },
  {
    path: "/forgot-password",
    name: routesName.forgotPassword,
    component: () => import("@interfaces/views/ForgotView.vue"),
    beforeEnter: (to, from, next) => {
      const userStore = useAccountStore();

      if (!userStore.isLogin) return next();

      return next(routesName.admin.home);
    },
  },
  {
    path: "/reset-password",
    name: routesName.resetPassword,
    component: () => import("@interfaces/views/ResetView.vue"),
    beforeEnter: (to, from, next) => {
      const userStore = useAccountStore();

      if (!userStore.isLogin) return next();

      return next(routesName.admin.home);
    },
  },
  {
    path: "/",
    name: routesName.root,
    component: () => import("@interfaces/views/AdminView.vue"),
    beforeEnter: (to, from, next) => {
      const userStore = useAccountStore();

      if (!userStore.isLogin) return next(routesName.login);

      return next();
    },
    children: [
      ...Object.keys(routesName.admin).map((name) => {
        return {
          name,
          path: `/${paramCase(name)}`,
          component: () =>
            import(`@interfaces/views/admin/${pascalCase(name)}View.vue`),
        };
      }),
      {
        name: "template-action",
        path: "/template/:action",
        component: () =>
          import("@interfaces/views/admin/TemplateActionView.vue"),
      },
      {
        name: "campaign-action",
        path: "/campaign/:action",
        component: () =>
          import("@interfaces/views/admin/CampaignActionView.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
