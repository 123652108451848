/* eslint-disable max-len */
const ip = "localhost";
// const ip = "192.168.1.81";

export default {
  env: "local",
  host: `http://${ip}:6678`,
  api: {
    host: "https://cdpapi.theadvance.com/api",
    product: "admin",
    token: "2b5fa55f4518e8f3cf810fce6591d347a69027d7916cdd54b6665f06b1a3ffe9",
  },
  upload: {
    host: "https://cdpapi.theadvance.com/",
    maxSizeUpload: 5242880,
  },
};
