import { DEFAULT_LIMIT } from "@common/constant";
import {
  IGetRoleDetail,
  IGetRoleList,
  IQueryRole
} from "@domain/interfaces/role-interface";
import RoleRepository from "@domain/repositories/role-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class RoleApiRepository implements RoleRepository {
  private api = new ApiService();

  async getList (query: IQueryRole): Promise<IGetRoleList> {
    const result = await this.api.get("/roles/list", query);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }

  async create (data: any): Promise<IGetRoleDetail> {
    const result = await this.api.post("/roles", data);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async update (id: string, data: any): Promise<IGetRoleDetail> {
    const result = await this.api.put(`/roles/${id}`, data);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async remove (id: string): Promise<IGetRoleDetail> {
    const result = await this.api.remove(`/roles/${id}`);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      data: result.data,
    };
  }
}
