/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import { IAccountLogin, IGetAccountDetail } from "@domain/interfaces/account-interface";

export default abstract class AccountRepository {

  async login (data: any): Promise<IAccountLogin> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async signUp (data: any): Promise<IAccountLogin> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async forgotPassword (data: any): Promise<IAccountLogin> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async resetPassword (data: any): Promise<IAccountLogin> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async logout (id: string): Promise<IGetAccountDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

}
