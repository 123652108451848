import "@config/index";
import "ant-design-vue/dist/reset.css";

import "./assets/css/styles.css";

import i18n from "@infrastructure/i18n";
import router from "@infrastructure/router";
import Antd from "ant-design-vue";
import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";
import {
  DesktopOutlined,
  DownOutlined,
  FileOutlined,
  KeyOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined
} from "@ant-design/icons-vue";

import { PiniaLogger } from "pinia-logger";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate).use(
  PiniaLogger({
    expanded: true,
    disabled: process.env.mode === "production",
  })
);

const app = createApp(App);
app
  .use(Antd)
  .use(router)
  .use(i18n)
  .use(pinia)
  .component(PieChartOutlined.name, PieChartOutlined)
  .component(TeamOutlined.name, TeamOutlined)
  .component(DesktopOutlined.name, DesktopOutlined)
  .component(FileOutlined.name, FileOutlined)
  .component(UserOutlined.name, UserOutlined)
  .component(KeyOutlined.name, KeyOutlined)
  .component(DownOutlined.name, DownOutlined);

app.mount("#app");
