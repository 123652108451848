/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
// import Segment from "@domain/entities/segment-entity";
import {
  IGetTemplateList,
  TemplateItem,
  ICreateTemplate
} from "@domain/interfaces/template-interface";

export default abstract class TemplateRepository {
  async getList (query?: TemplateItem): Promise<IGetTemplateList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async getAppScreen (query?: any): Promise<any> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async create (data: any): Promise<ICreateTemplate> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async update (data: any): Promise<ICreateTemplate> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async upload (data: any): Promise<any> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async oaList (data: any): Promise<any> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async oaTemplateList (data: any): Promise<any> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
  
  
  // async duplicate (data: any): Promise<TemplateItem> {
  //   throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  // }

}
