export const routesName = {
  login: "login",
  signUp: "sign-up",
  forgotPassword: "forgot-password",
  resetPassword: "reset-password",
  root: "root",
  admin: {
    home: "home",
    segment: "segment",
    template: "template",
    campaign: "campaign",
    rule: "rule",
    config: "config",
    account: "account",
    role: "role",
  },
};
