/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import Role from "@domain/entities/role-entity";
import { IGetRoleDetail, IGetRoleList, IQueryRole } from "@domain/interfaces/role-interface";

export default abstract class RoleRepository {
  async getList (query?: IQueryRole): Promise<IGetRoleList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async create (data: Role): Promise<IGetRoleDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async update (id: string, data: Partial<Role>): Promise<IGetRoleDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async remove (id: string): Promise<IGetRoleDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
