import { DEFAULT_LIMIT } from "@common/constant";
// import {
//   ICreateTemplate
// } from "@domain/interfaces/template-interface";
import CampaignRepository from "@domain/repositories/campaign-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class CampaignApiRepository implements CampaignRepository {
  private api = new ApiService();

  async getList (query: any): Promise<any> {
    const result = await this.api.get("/campaign/get", query);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      data: {
        result: result.data?.result || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
        offset: result.data?.ofset || 0,
      },
      status: result.status || 0,
    };
  }

  async userSegments (data: any): Promise<any> {
    const result :any = await this.api.post("/campaign/user-segments", data);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      total: result?.total || 0,
    };
  }

  async create (data: any): Promise<any> {
    const result = await this.api.post("/campaign/create", data);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      status: result.status || 0,
    };
  }

  async update (data: any): Promise<any> {
    const result = await this.api.post("/campaign/update", data);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      status: result.status || 0,
    };
  }

  // async remove (id: string): Promise<IGetSegmentDetail> {
  //   const result = await this.api.remove(`/segment/${id}`);

  //   return {
  //     isSuccess: result.status === 1,
  //     message: result.message || "",
  //     data: result.data,
  //   };
  // }
}
