/* eslint-disable max-len */
export default {
  env: "development",
  host: "https://web.example.com",
  api: {
    host: "https://example.com",
    product: "admin",
    token: "2b5fa55f4518e8f3cf810fce6591d347a69027d7916cdd54b6665f06b1a3ffe9",
  },
  upload: {
    host: "https://upload.ngocdunggroup.com.vn/upload/images?product=THEADVANCE_APP&token=de4fefbc0f4602388af2",
    maxSizeUpload: 5242880,
  },
};
