/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import Segment from "@domain/entities/segment-entity";
import {
  IGetSegmentDetail,
  IGetSegmentList,
  IQuerySegment
} from "@domain/interfaces/segment-interface";

export default abstract class SegmentRepository {
  async getList (query?: IQuerySegment): Promise<IGetSegmentList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async getField (query?: IQuerySegment): Promise<IGetSegmentList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async create (data: any): Promise<IGetSegmentDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async update (data: any): Promise<IGetSegmentDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async check (data: any): Promise<any> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async save (data: any): Promise<any> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
  
  async duplicate (data: any): Promise<IGetSegmentDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

}
