import { DEFAULT_LIMIT } from "@common/constant";
import {
  IGetSegmentList,
  IQuerySegment,
  IGetSegmentDetail
} from "@domain/interfaces/segment-interface";
import SegmentRepository from "@domain/repositories/segment-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class SegmentApiRepository implements SegmentRepository {
  private api = new ApiService();

  async getList (query: IQuerySegment): Promise<IGetSegmentList> {
    const result = await this.api.get("/segment/get", query);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      data: {
        result: result.data?.result || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
        ofset: result.data?.ofset || 0,
      },
    };
  }

  async getField (): Promise<IGetSegmentList> {
    const result = await this.api.get("/segment/get-fields",{});

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      data: {
        result: result.data || {},
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
        ofset: result.data?.ofset || 0,
      },
    };
  }

  async create (data: any): Promise<IGetSegmentDetail> {
    const result = await this.api.post("/segment/create", data);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async update (data: any): Promise<IGetSegmentDetail> {
    const result = await this.api.post("/segment/update", data);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async duplicate (data: any): Promise<IGetSegmentDetail> {
    const result = await this.api.post("/segment/duplicate", data);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async save (data: any): Promise<any> {
    const result: any = await this.api.save("/segment/export", data);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      status: result.status || 0,
    };
  }
  

  async check (data: any): Promise<any> {
    const result: any = await this.api.post("/segment/check", data);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      status: result.status || 0,
      count: result.count || 0,
    };
  }
}
