import AccountRepository from "@domain/repositories/account-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class AccountApiRepository implements AccountRepository {
  private api = new ApiService();

  async login (data: any) {
    const result = await this.api.post("/account/login", data);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async signUp (data: any) {
    const result = await this.api.post("/account/register", data);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async forgotPassword (data: any) {
    const result = await this.api.post("/account/forgot-pass", data);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      data: result?.data,
    };
  }

  async resetPassword (data: any) {
    const result = await this.api.post("/account/reset-pass", data);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      data: result?.data,
    };
  }

  async logout (id: string) {
    const result = await this.api.post("/account/logout", { id });

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      data: result.data,
    };
  }
}
