import repository from "@config/repository";
import AccountRepository from "@domain/repositories/account-repository";
import { IRegisterBody } from "@domain/interfaces/account-interface";

export default async function forgotPasswordUseCase (values: IRegisterBody) {
  const accountRepository =
    repository.get<AccountRepository>(AccountRepository);

  return await accountRepository.forgotPassword(values);
}
