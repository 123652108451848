import { DEFAULT_LIMIT } from "@common/constant";
import {
  IGetTemplateList,
  TemplateItem,
  ICreateTemplate
} from "@domain/interfaces/template-interface";
import TemplateRepository from "@domain/repositories/template-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class TemplateApiRepository implements TemplateRepository {
  private api = new ApiService();

  async getList (query: TemplateItem): Promise<IGetTemplateList> {
    const result = await this.api.get("/template/get", query);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      data: {
        result: result.data?.result || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
        offset: result.data?.ofset || 0,
      },
      status: result.status || 0,
    };
  }

  async getAppScreen (query: any): Promise<any> {
    const result = await this.api.get("/template/get-app-screen", query);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      data: result.data || [],
      status: result.status || 0,
    };
  }

  async create (data: any): Promise<ICreateTemplate> {
    const result = await this.api.post("/template/create", data);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      status: result.status || 0,
    };
  }

  async update (data: any): Promise<ICreateTemplate> {
    const result = await this.api.post("/template/update", data);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      status: result.status || 0,
    };
  }

  async upload (data: any): Promise<any> {
    const result:any = await this.api.upload("/file/upload", data);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      status: result.status || 0,
      url: result.url,
    };
  }

  async oaList (query: any): Promise<any> {
    const result = await this.api.get("/template/get-oa", query);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      data: {
        result: result.data?.result || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
        offset: result.data?.ofset || 0,
      },
      status: result.status || 0,
    };
  }

  async oaTemplateList (query: any): Promise<any> {
    const result = await this.api.get("/template/get-oa-template", query);

    return {
      isSuccess: result.status === 1,
      message: result.message || "",
      data: {
        result: result.data?.result || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
        offset: result.data?.ofset || 0,
      },
      status: result.status || 0,
    };
  }
}
