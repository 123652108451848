import "reflect-metadata";

import { Container } from "inversify";

import AccountRepository from "@domain/repositories/account-repository";
import AccountRepositoryApi from "@infrastructure/repositories/api/account-api-repo";

import RoleRepository from "@domain/repositories/role-repository";
import RoleRepositoryApi from "@infrastructure/repositories/api/role-api-repo";

import RuleRepository from "@domain/repositories/rule-repository";
import RuleRepositoryApi from "@infrastructure/repositories/api/rule-api-repository";

import SegmentRepository from "@domain/repositories/segment-repository";
import SegmentRepositoryApi from "@infrastructure/repositories/api/segment-api-repo";

import TemplateRepository from "@domain/repositories/template-repository";
import TemplateRepositoryApi from "@infrastructure/repositories/api/template-api-repo";

import CampaignRepository from "@domain/repositories/campaign-repository";
import CampaignRepositoryApi from "@infrastructure/repositories/api/campaign-api-repo";

const repository = new Container();

if (process.env.VUE_APP_REPOSITORY === "api") {
  repository
    .bind<AccountRepository>(AccountRepository)
    .to(AccountRepositoryApi);
  repository.bind<RuleRepository>(RuleRepository).to(RuleRepositoryApi);
  repository.bind<RoleRepository>(RoleRepository).to(RoleRepositoryApi);
  repository
    .bind<SegmentRepository>(SegmentRepository)
    .to(SegmentRepositoryApi);
  repository.bind<TemplateRepository>(TemplateRepository).to(TemplateRepositoryApi);
  repository.bind<CampaignRepository>(CampaignRepository).to(CampaignRepositoryApi);

}

export default repository;
