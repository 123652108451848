/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
// import Segment from "@domain/entities/segment-entity";
// import {
//   IGetTemplateList,
//   TemplateItem,
//   ICreateTemplate
// } from "@domain/interfaces/template-interface";

export default abstract class CampaignRepository {
  async getList (query?: any): Promise<any> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async userSegments (data?: any): Promise<any> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
  
  async create (data: any): Promise<any> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async update (data: any): Promise<any> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
