/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import Rule from "@domain/entities/rule-entity";
import { IGetRuleDetail, IGetRuleList, IQueryRule, IRuleSubjectList } from "@domain/interfaces/rule-interface";

export default abstract class RuleRepository {
  async getList (query?: IQueryRule): Promise<IGetRuleList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async create (data: Rule): Promise<IGetRuleDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async update (id: string, data: Partial<Rule>): Promise<IGetRuleDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async remove (id: string): Promise<IGetRuleDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async getSubjectList (): Promise<IRuleSubjectList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
