export default class Account {
  account_company: string;
  account_group: string;
  account_id: string;
  account_name: string;
  account_role: string;
  updated_at: Date;
  token: string;
  partners: any;
  constructor (data?: any) {
    this.account_name = data?.account_name || "";
    this.account_group = data?.account_group || "";
    this.account_id = data?.account_id || "";
    this.account_role = data?.account_role || "";
    this.account_company = data?.account_company || "";
    this.updated_at = data?.updated_at || "";
    this.token = data?.token || "";
    this.partners = data?.partners || [];
  }
}
