import loginUseCase from "@domain/use-cases/account/login";
import signUpUseCase from "@domain/use-cases/account/sign-up";
import forgotPasswordUseCase from "@domain/use-cases/account/forgot-password";
import resetPasswordUseCase from "@domain/use-cases/account/reset-password";
import logoutUseCase from "@domain/use-cases/account/logout";

import Account from "@domain/entities/account-entity";
import {
  ILoginBody,
  IQueryAccount,
  IRegisterBody
} from "@domain/interfaces/account-interface";

import { defineStore } from "pinia";
import { useAlertStore } from "./alert-store";

export const useAccountStore = defineStore("account", {
  state: () => ({
    loading: false,
    loginUser: new Account(),
    token: "",
    partner: {} as any,
    partnerLists: [] as any,
    table: {
      fetching: false,
      data: [] as Array<Account>,
      total: 0,
      limit: 10,
      page: 1,
      searchQuery: <IQueryAccount>{},
    },
  }),

  getters: {
    getLoginUser: (state) => state.loginUser,
    isLogin: (state) => !!state.token,
    searchQuery: (state) => state.table.searchQuery,
  },
  persist: {
    key: "dataAccess",
    paths: ["loginUser", "token", "partner"],
  },
  actions: {
    async login (data: ILoginBody) {
      const result = await loginUseCase(data);
      const alert = useAlertStore();

      if (result.isSuccess) {
        this.loginUser = result.data;
        this.token = result.data.token;
        const curent = result.data.partners?.find(
          (item: any) => item.code === result.data.account_company
        );
        this.partner = { label: curent.name, value: curent.code };
        alert.success(`Welcome back ${this.loginUser.account_name}!`);
      } else {
        alert.error(result.message);
      }

      return result.isSuccess;
    },

    async signUp (data: IRegisterBody) {
      const result = await signUpUseCase(data);
      const alert = useAlertStore();

      if (result.isSuccess) alert.success("You have successfully registered!");
      else alert.error(result.message);

      return result.isSuccess;
    },

    async forgotPassword (data: { email: string }) {
      const result = await forgotPasswordUseCase(data);
      const alert = useAlertStore();

      if (result.isSuccess) alert.success(result.message);
      else alert.error(result.message);

      return result;
    },

    async resetPassword (data: any) {
      const result = await resetPasswordUseCase(data);
      const alert = useAlertStore();

      if (result.isSuccess) alert.success(result.message);
      else alert.error(result.message);

      return result.isSuccess;
    },

    async logout () {
      const result = await logoutUseCase(this.loginUser.account_id);
      this.loginUser = new Account();
      this.token = "";

      if (result.isSuccess) useAlertStore().success("Đăng xuất thành công");

      return result.isSuccess;
    },
  },
});
